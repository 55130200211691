// import 'react-app-polyfill/stable';
// import smoothscroll from 'smoothscroll-polyfill';

// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// issue: https://github.com/facebook/create-react-app/issues/9906
import './sentry';
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import '@/i18n';

const FullApp = lazy(() => import('./entry'));

const codeEl = document.querySelector('head > meta[name="error-code"]');
const errorCode = (codeEl && codeEl.getAttribute('content')) ?? undefined;

// smoothscroll.polyfill();

declare global {
  interface Window {
    komensky: Record<string, any>;
  }
}

const render = () => {
  window.komensky = {
    version: import.meta.env.VITE_VERSION,
    commit: import.meta.env.VITE_COMMIT,
  };
  const rootEl = document.getElementById('root');
  ReactDOM.render(
    <Suspense fallback={null}>
      <FullApp errorCode={errorCode} />
    </Suspense>,
    rootEl
  );
};

render();
