let sentryInit = false;

const ignoreErrors = [
  'change_ua',
  '[MSW] Failed to register the Service Worker',
];

async function trySentry() {
  if (sentryInit) return;
  if (import.meta.env.DEV) {
    if (!import.meta.env.VITE_ENABLE_DEV_SENTRY) return;
    console.info(
      "Attempting to enable Sentry even though we're in dev mode... Change VITE_ENABLE_DEV_SENTRY if unexpected"
    );
  }
  const el = window.document.querySelector('head > link[rel="sentry-dsn"]');
  const dsn = el?.getAttribute('href');
  if (!dsn) return;

  try {
    new URL(dsn);
    const Sentry = await import('@sentry/react');
    const tracePropagationTargets = ['localhost'];
    Sentry.init({
      dsn,
      ignoreErrors,
      release: import.meta.env.VITE_VERSION,
      environment: import.meta.env.MODE,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          // Should be updated once in a while
          // @url: https://raw.githubusercontent.com/getsentry/sentry-javascript/develop/packages/replay-worker/examples/worker.min.js
          workerUrl: '/assets/sentryWorker.7.77.0.js',
        }),
      ],
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets,
      replaysSessionSampleRate: 0.02,
      replaysOnErrorSampleRate: 1.0,
    });
    sentryInit = true;
  } catch (e) {
    console.error('Failed to initialize Sentry:', e);
  }
}

trySentry();
